
@font-face {
    font-family:'DIN';
    src: url('./DIN/35E006_0_0.eot');
    src: url('./DIN/35E006_0_0.eot?#iefix') format('embedded-opentype'),
        url('./DIN/35E006_0_0.woff2') format('woff2'),
        url('./DIN/35E006_0_0.woff') format('woff'),
        url('./DIN/35E006_0_0.ttf')  format('truetype');
    font-weight:400;
    font-style:normal;
}
@font-face {
    font-family:'DIN';
    src: url('./DIN/35E006_1_0.eot');
    src: url('./DIN/35E006_1_0.eot?#iefix') format('embedded-opentype'),
        url('./DIN/35E006_1_0.woff2') format('woff2'),
        url('./DIN/35E006_1_0.woff') format('woff'),
        url('./DIN/35E006_1_0.ttf')  format('truetype');
    font-weight:400;
    font-style:italic;
}
@font-face {
    font-family:'DIN';
    src: url('./DIN/35E006_2_0.eot');
    src: url('./DIN/35E006_2_0.eot?#iefix') format('embedded-opentype'),
        url('./DIN/35E006_2_0.woff2') format('woff2'),
        url('./DIN/35E006_2_0.woff') format('woff'),
        url('./DIN/35E006_2_0.ttf')  format('truetype');
    font-weight:600;
    font-style:normal;
}
@font-face {
    font-family:'DIN Bold';
    src: url('./DIN/35E006_3_0.eot');
    src: url('./DIN/35E006_3_0.eot?#iefix') format('embedded-opentype'),
        url('./DIN/35E006_3_0.woff2') format('woff2'),
        url('./DIN/35E006_3_0.woff') format('woff'),
        url('./DIN/35E006_3_0.ttf')  format('truetype');
    font-weight:600;
    font-style:italic;
}
@font-face {
    font-family:'DIN';
    src: url('./DIN/35E006_4_0.eot');
    src: url('./DIN/35E006_4_0.eot?#iefix') format('embedded-opentype'),
        url('./DIN/35E006_4_0.woff2') format('woff2'),
        url('./DIN/35E006_4_0.woff') format('woff'),
        url('./DIN/35E006_4_0.ttf')  format('truetype');
    font-weight:800;
    font-style:normal;
}
@font-face {
    font-family:'DIN';
    src: url('./DIN/35E006_5_0.eot');
    src: url('./DIN/35E006_5_0.eot?#iefix') format('embedded-opentype'),
        url('./DIN/35E006_5_0.woff2') format('woff2'),
        url('./DIN/35E006_5_0.woff') format('woff'),
        url('./DIN/35E006_5_0.ttf')  format('truetype');
    font-weight:900;
    font-style:normal;
}
